.ad-container {
  width: 100%;
  background-color: #eee;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  --ad-display: flex;
  --ad-slot-scale: 1;
  --ad-slot-transform-origin: 0 0;
  --_ad-slot-min-height: var(--ad-slot-min-height, 0);
  --ad-marker-lineheight: 1.625rem; /* 26 px */

  --adinfo-button-size: 1.5rem;

  .ad-container__marker {
    margin: 0;
    font-size: 0.875rem;
    line-height: var(--ad-marker-lineheight); /* 26px */
    text-align: center;
    text-transform: uppercase;
  }

  &.ad-container--widget {
    background: none;
  }
}

.ad-container {
  --ad-block-margin: 1.25rem;
  @media (min-width: 768px) {
    --ad-block-margin: 1.875rem;
  }

  padding-bottom: 0.75rem;
  margin-block: var(--ad-block-margin);
}

/* first visible ad block, can be either first child, or when mobile, ad container following first-child ad-container. */
.ad-container:first-child,
.ad-container:first-child + .ad-container,
.ad-container + *:not(.ad-container) {
  margin-block-start: 0;
}

.ad-container[data-slotname^="mob"] {
  --ad-slot-min-height: 320px;
  display: var(--ad-display) !important;
  @media (min-width: 768px) {
    display: none !important;
  }
}

.ad-container[data-slotname="mob_widget"] {
  --ad-slot-min-height: 260px;
}

.ad-container[data-slotname^="panorama"] {
  display: none !important;
  --ad-slot-min-height: 240px;
  @media (min-width: 768px) {
    display: var(--ad-display) !important;
  }
}

.ad-container[data-slotname="outsider"] {
  --ad-slot-min-height: 360px;
  display: none !important;
  @media (min-width: 84.5em) {
    display: var(--ad-display) !important;
  }
}

b-a-d {
  /*
  Flex seems to be the default.
  In order to avoid layout shift when the pubads script sets these
  to display: none briefly during page load, we use !important to make sure they still take up space.
  */
  display: flex !important;
  text-align: center;
  height: calc(var(--ad-slot-height, auto) * var(--ad-slot-scale)) !important; /* this will be invalid before the ad has loaded dimensions, but that is by design - we don't want to constrain height initially. */
  min-height: calc(var(--_ad-slot-min-height, 0) * var(--ad-slot-scale));

  > :first-child {
    transform-origin: var(--ad-slot-transform-origin);
    transform: scale(var(--ad-slot-scale));
  }
}

b-a-d .ad-info_button {
  display: block;
  width: var(--adinfo-button-size);
  height: var(--adinfo-button-size);
  font-size: 1rem;
  line-height: 1rem;
  /* calculate top offset in order to center inside ad marker line height */
  top: calc(
    (
      var(--ad-marker-lineheight, 0rem) -
      (
        (var(--ad-marker-lineheight) - var(--adinfo-button-size))
        / 2)
    )
    * -1
  ); /* make sure that line height has rem unit in order to be able to flip with -1. */

  svg {
    padding: 0.1875rem; /* 3px */
  }
}

b-a-d[slotname^="panorama"] {
  max-width: 100%;
  align-items: center; /** some sites are configured to show shorter panoramas, so let's center them. */
}
