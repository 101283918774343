body {
  background: var(--color-background);
  font-family: var(--font-base-family), var(--font-fallback-family), var(--font-system-fallback);
  margin: 0;
}

figure {
  margin: 0;
}

a {
  color: var(--color-brand);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
