.share-button {
    padding: 0 0.5rem;
    line-height: 1.3rem;
    display: none;
}

.share-button:defined {
    display: initial;
}

.share-button-links {
    display: flex;
}

.share-button-links a, .share-button {
    display: inline-block;
    margin-right: var(--spacing-m);
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border: 1px solid var(--color-brand);
    border-radius: 1.5rem;
    line-height: 1.5rem;
    background-color: white;
    color: var(--color-brand);
    text-align: center;
    text-decoration: none;

    &.email {
        font-size: 1.2em;
    }
}

.share-button-links a:hover{
    background-color: var(--color-brand);
    color: white;
    cursor: pointer;
}

.share-button:active {
    background-color: var(--color-brand);
    color: white;
}