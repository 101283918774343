.skip-link {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px);
  white-space: nowrap;
}

.skip-link:focus-visible {
  right: 0;
  width: auto;
  width: fit-content;
  margin-inline: auto;
  height: auto;
  clip: initial;
  clip-path: none;
  padding: 0.5rem;
  display: block;
  background-color: var(--color-background);
  color: var(--color-brand);
  border: 1px solid currentColor;
}
