.account-menu {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-text);
  img {
    margin-left: 2px;
  }

  &:hover {
    text-decoration: underline;
  }
  float: left;
}
