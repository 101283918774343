.caption {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  overflow-wrap: break-word; /* prevent blowout or clipping for long words. */
  text-wrap: pretty; /* avoid typographic orphans, if text is short */
}

.caption__image-credit {
  color: var(--color-text-secondary);
}
