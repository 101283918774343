@keyframes spin {
  to { transform: rotate(360deg); }
}

.spinner {
  display: block;
  width: 30px;
  height: 30px;
  margin: 10px auto;
  border-radius: 50%;
  border: 3px solid rgb(107 107 107 / 30%);
  border-top-color: #000000;
  animation: spin 1s ease-in-out infinite;
}
