@import url('./components/article-dates/style.css');
@import url('./components/share-button/style.css');
@import url('./components/article-section-links/style.css');


.article__header,
.article__body {
  figure img {
    --article-image-border-radius: 6px;
    border-radius: var(--article-image-border-radius);
  }
}

.article-wrapper {
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  --article-block-margin: 1.75rem;

  .outsider-content,
  .article-wrapper__visibility-tracker {
    grid-column: 1;
    grid-row: 1;
  }

  @media (min-width: 40em) {
    --article-block-margin: 2.5rem;
  }
}

.article-wrapper__visibility-tracker {
  position: sticky;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 51vh;
  max-height: 100%;
  pointer-events: none;
}

.article-wrapper + .article-wrapper {
  margin-top: var(--article-block-margin);
}

.article {
  --article-grid-columns: [full-start] 1fr [content-start] minmax(18rem, 40rem) [content-end] 1fr [full-end];
  --article-content-block-margin: 1.5em;

  font-size: 1.125rem;

  display: grid;
  grid-template-columns: var(--article-grid-columns);

  @supports not (display: grid) {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }

  > * {
    grid-column: full;
  }

  .prose {
    @media (min-width: 63.25em) {
      --prose-block-margin: 2em;
    }
  }

  blockquote {
    font-weight: 500;
    font-style: italic;
    font-size: 1.5rem;
    padding-left: var(--spacing-m);
    &::before {
      content: open-quote;
    }
    &::after {
      content: close-quote;
    }
  }
}

.article__header,
.article__body,
.article__footer {
  display: grid;
  grid-template-columns: var(--article-grid-columns);

  > * {
    grid-column: content;
  }
}

.article__header {
  figure {
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  figure {
    margin-bottom: 1.75rem;
  }
}

.article__preamble {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.3;

  p {
    margin: 0;
  }

  p + p {
    margin-top: 1em;
  }
}
.article__body {
  margin-top: var(--article-block-margin);
  > .ad-container {
    grid-column: full;

    + *:not(.ad-container) {
      margin-top: 0;
    }
  }
}

.article__footer {
  margin-top: 1.25rem;
}

.article__header-native {
  display: flex;
}

.article__header-native-banner {
  grid-column: full;
  display: block;
  background-color: #000;
  z-index: 10;
  position: sticky;
  top: -1px;
  color: #ffffff;
  padding: var(--spacing-s) var(--spacing-m);
  margin-bottom: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 48em) {
    margin-left: -1rem;
    margin-right: -1rem;
    /* margin-bottom: 2.5rem; */
  }
}

.article__header-native-banner[data-stuck-to="top"] {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.article__headline {
  font-weight: 900;
  font-size: 1.625rem;
  line-height: 1.15;
  margin-top: 0.25rem;
  margin-bottom: 1.25rem;
  text-wrap: balance;

  @media (min-width: 63.25em) {
    font-size: 2.375rem;
    margin-bottom: 1.75rem;
  }
}
