.menu-mobile {
  --menu-mobile-size: 2.5rem;
  --menu-mobile-line-height-px: 20;
  --menu-mobile-font-size-px: 14;
  a {
    --menu-mobile-font-size: calc((var(--menu-mobile-font-size-px) / 16) * 1rem);
    --menu-mobile-line-height: calc(var(--menu-mobile-line-height-px) / var(--menu-mobile-font-size-px));
    --menu-mobile-block-padding: calc((var(--menu-mobile-size) - var(--menu-mobile-line-height) * 1rem) / 2);
    display: inline-block;
    color: var(--color-text);
    text-decoration: none;
    padding: var(--menu-mobile-block-padding) 1.25rem var(--menu-mobile-block-padding) 0;
    cursor: pointer;
    font-size: var(--menu-mobile-font-size);
    line-height: var(--menu-mobile-line-height);
  }

  .top-level {
    --menu-mobile-font-size-px: 16;
    font-weight: 500;
  }

  a[aria-current="page"] {
    font-weight: 700;
  }
}

.menu-mobile__nav {
  max-width: 82.5rem;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: var(--spacing-l);
  padding-left: 0;
  padding-bottom: var(--spacing-m);
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 999;
  list-style-type: none;

  & > li {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
}

.menu-mobile__toggle-button {
  display: inline-block; /* Note: don't change this, as setting another value seems to trigger a bug in VoiceOver/Safari. */
  height: var(--menu-mobile-size);
  width: var(--menu-mobile-size);
  padding: 0.4375rem; /* 7px */
  border-radius: 50%;
  border: 1px solid var(--color-border-separator);
  background-color: white;
  transition: background-color .1s ease-in-out;

  &:hover, &:active {
    background-color: var(--color-border-separator);
  }
}

.menu-mobile__sub-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;

  & ~ ul {
    padding-left: var(--spacing-l);
    list-style-type: none;
  }
}

.menu-mobile__sub-menu-toggle,
.menu-navbar__sub-menu-toggle,
.menu-navbar__sub-menu-fulltoggle,
.menu-mobile__sub-menu-fulltoggle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  border-radius: 999em;
}

.menu-mobile__sub-menu-fulltoggle {
  width: 100%;
  justify-content: space-between;
}

.menu-mobile__sub-menu-toggle {
  height: var(--menu-mobile-size);
  width: var(--menu-mobile-size);

  &[aria-expanded="true"] {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }
}

.menu-navbar__sub-menu-toggle  {
  height: 1.5rem;
  width: 1.5rem;
}

.menu-mobile__sub-menu-fulltoggle,
.menu-navbar__sub-menu-fulltoggle {
  padding: .375rem 0.25rem .4375rem 0;
  border-radius: 0;
  font: inherit;
  cursor: pointer;

  .menu-mobile__sub-menu-text,
  .menu-navbar__sub-menu-text {
    padding-right: 0.25rem;
  }
}

.menu-mobile__sub-menu-fulltoggle img {
  padding-inline: 0.5rem;
}

.sub-menu__toggle-button,
.menu-mobile__sub-menu-toggle,
.menu-navbar__sub-menu-toggle {
  padding: 0;
  cursor: pointer;
  transition: background-color .25s ease-in-out;

  &:hover, &:active {
    background-color: var(--color-border-separator);
  }
}

.menu-desktop {
  li {
    margin-right: var(--spacing-l);
  }

  li a {
    display: flex;
    align-items: center;
    color: var(--color-text);
    text-decoration: none;
    padding: .5rem 0.25rem .5rem 0;
    font-size: 1rem;

    &:hover, &:active, &:focus {
      text-decoration: underline;
      cursor: pointer;
    }

    &[aria-current="page"] {
      font-weight: 700;
    }

  }
}

.menu-desktop__list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-desktop__sub-menu {
  display: flex;
  align-items: center;
}

.menu-desktop__dropdown {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    max-height: 22.5rem;
    padding: 0 2rem;
    border-left: 1px solid #D2D2D2;
  }

  li {
    width: 12rem;
    margin: 0 1rem 0 0;
  }
}

.menu-desktop__dropdown-container {
  display: flex;
  padding: 2rem 0;
}

.menu-desktop__dropdown-title {
  margin-top: .5rem;
  margin-right: 1.5rem;
  width: 11rem;
  font-weight: 700;
  text-align: right;
}
