.article__dates {
  display: flex;
  flex-wrap: wrap;
  gap: 1.125rem;
  font-size: 0.875rem;
  p {
    margin: 0;
    color: #767676;
    &:first-child {
      color: var(--color-text);
    }
  }
}
