.fullscreen-column {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > .fullscreen-column__fill {
    flex-grow: 1;
  }
}

.container {
  max-width: 82.5rem;
  width: calc(100% - (2 * 1rem));
  margin: 0 auto auto auto;
}

.main-content {
  padding: var(--spacing-m) 0;
}

.section-item + .section-item {
  margin-top: var(--spacing-m);
}

.clickable-surface {
  position: relative;

  /**
   * 1. Since we remove the outline of the link inside the element, and the border
   *    color stays the same in forced-colors mode, we need to take care of
   *    a special color for that mode. Uses system color keyword.
   */
   &:where(:focus-within) {
    outline: 2px auto -moz-mac-focus-ring;
    outline: 2px auto -webkit-focus-ring-color;
    /* box-shadow: 0 0 0 4px #fff; */
    @media (forced-colors: active) {
      outline-color: Highlight; /* See 1. */
    }
  }
  /**
   * 1. The rule for focus within could have acheived the following, but
   *    support for :has() is not great yet, so this rule negates the previous one
   *    where the link should not have visible focus. This prevents a flash of outline
   *    in browsers supporting :has().
   */
  &:where(:focus-within:not(:has(.clickable-surface__link:focus-visible))) {
    outline: none; /* See 1. */
    box-shadow: none;
  }

  /**
   * 1. Since focus is drawn on the card using :focus-within,
   *    we don't need focus styles on the actual call to action.
   * 2. Makes sure that the link is on top of the card.
   */
  .clickable-surface__link {
    &:where(:focus-visible) {
      outline: none; /* See 1. */
      box-shadow: none;
    }
  }



  &:hover {
    --default-link-decoration: underline;

    /* revert for links in override */
    & .clickable-surface__override {
      --default-link-decoration: revert;
    }

    /* in supported browsers: revert when hovering override */
    &:has(.clickable-surface__override:hover) {
      --default-link-decoration: revert;
    }
  }
}

.clickable-surface__hidden-link {
  grid-area: var(--clickable-surface-grid-area, 1 / 1 / -1 / -1);
  color: transparent;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

.clickable-surface__override {
  position: relative;
  z-index: 2;
}

.visually-hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px);
  white-space: nowrap;
}

.prose {
  /* Remove default margins in supporting browsers */
  :where(p, h2, h3, h4, h5, h6, img, figure, blockquote) {
    margin: 0;
  }

  /* Default margin between content blocks */
  > * + * {
    margin-top: var(--prose-block-margin, 1.5rem);
  }

  > *:where(h2,h3,h4,h5,h6) + *:not(.ad-container) {
    margin-top: var(--prose-heading-bottom-margin, 0.75rem);
  }

  > * + *:where(ul, ol) {
    margin-top: var(--prose-paragraph-intra-margin, 1em);
  }

  p {
    font-size: var(--prose-font-size, 1.125rem);
    line-height: 1.5;
    text-wrap: pretty; /* Not widely supported, but prevents widows/orphans */

    /* Adjacent paragraphs have slightly different intra margin */
    & + p {
      margin-top: var(--prose-paragraph-intra-margin, 1em);
    }
  }

  h2 {
    font-size: var(--prose-h2-font-size, 1.25rem);
    line-height: var(--prose-h2-line-height, 1.3);

    @media (min-width: 63.25em) {
      --prose-h2-font-size: var(--prose-h2-font-size-desktop, 1.375rem);
    }
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  & > ol, & > ul {
    margin-bottom: 0;
    padding-left: var(--spacing-l);

    li + li {
      margin-top: var(--spacing-s);
    }
  }
}
