.site-footer {
  background-color: var(--color-footer-background);
  padding: var(--spacing-l) 0;
  margin-top: 5rem;
}

.site-footer-content {
  display: grid;
  grid-gap: 1.875rem;
  grid-template-columns: repeat(auto-fit,minmax(18rem,1fr));
}

.site-footer-content__group {
  margin: 0;
  padding: 0;
}

.site-footer-content__group-item {
  color: white;
  list-style: none;
  margin-top: 1rem;
  font-size: 0.81rem;
  white-space: pre-line;

  button {
    background: unset;
    border: none;
    padding: 0;
  }
}

.site-footer-content__group-item--link {
  color: white;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.site-footer-content__logo {
  margin-top: 1.25rem;
}
