.author-page-header {
  border-bottom: 1px solid var(--color-border-separator);
  padding-bottom: var(--spacing-l);
  margin-bottom: var(--spacing-l);
}

.author-page-header__top {
  margin-bottom: var(--spacing-l);
  display: grid;
  grid-template-areas:
    "image"
    "text";

  text-align: center;

  @media (min-width: 22.5em) {
    text-align: left;
    align-content: center;
    grid-template-areas:
    "image text";
    grid-template-columns: auto 1fr;
    grid-template-rows: minmax(0, 1fr) minmax(0, auto);
  }
}

.author-page-header__text {
  grid-area: text;
}

.author-page-header__name {
  margin: 0;
  font-size: 1.125rem;

  @media (min-width: 40em) {
    font-size: 1.25rem;
  }
}

.author-page-header__email {
  font-size: 1rem;
}

.author-page-header__image {
  grid-area: image;
  align-self: start;
  justify-self: center;
  margin-right: 0.625rem;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.author-page-header__description {
  font-size: 1.125rem;

    @media (min-width: 63.25em) {
      font-size: 1.25rem;
      width: 90%;
    }
}
