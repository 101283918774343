.article__section-links {
  display: flex;
  gap: 0.5rem;
}

.article__section-links-link {
  font-size: 1rem;
  color: var(--color-brand);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
