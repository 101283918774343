.image-figure {
  display: grid;
  grid-template-columns: 100%;

  img {
    grid-area: 1 / 1;
    width: 100%;
    height: 100%;
  }
}
