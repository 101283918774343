.search-form {
  display: flex;
  position: relative;
  width: 100%;
  margin: var(--spacing-m) 0;
  gap: var(--spacing-s);
  font-size: 1rem;
  font-family: var(--font-base-family);

  > img {
    position: absolute;
    left: 12px;
    top: 12px;
  }

  input[type="search"] {
    background: white;
    padding: 14px 14px 14px 45px;
    width: 100%;
    font-size: 1rem;
    border: 1px solid var(--color-border-separator);
    border-radius: 100px;
    font-size: 1rem;
    font-family: var(--font-base-family);

    &:focus {
      outline: 0;
      border-color:  var(--color-footer-background);
    }
  }

  button[type="submit"] {
    border: none;
    cursor: pointer;
    transition: .25s ease-in-out;
    display: none;
    border-radius: 100px;
    color: white;
    background-color: var(--color-brand);
    padding: 10px 18px;
    font-family: var(--font-base-family);
    font-size: 1rem;

    &:hover, &:focus {
      background-color: #822423;
    }

    @media (min-width: 40em) {
      display: inline;
      width: 100px;
    }
  }
}
