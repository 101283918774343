.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
  padding: var(--spacing-m) 0;

  li {
    list-style: none;
    padding: 0 var(--spacing-s);
  }
}

.pagination__link {
  a {
    color: var(--color-brand);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.pagination__link--disabled {
  a {
    font-weight: bold;
    text-decoration: none;
    pointer-events: none;
    color: var(--color-text);
  }
}
