.author {
  display: grid;
  align-items: center;
  width: 100%;
  padding: 0;
  padding: var(--spacing-m) 0;
  border-bottom: 1px solid #D2D2D2;
  --author-meta-color: #767676;
  grid-template-areas:
    "image details arrow"
    "image details arrow";
  grid-template-columns: auto 1fr minmax(0, auto);
}

.author--linked {
  --author-meta-color: var(--color-brand);

  &:hover {
    background-color: #f5f5f5;
  }
}

.author__details {
  grid-area: details;
}

.author__image {
  grid-area: image;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  background-color: lightgray;
  border-radius: 50%;
  max-width: 100%;
  height: auto;

  @media (min-width: 40em) {
    width: 3.125rem;
    height: 3.125rem;
  }
}

.author__name {
  font-weight: bolder;
}

.author__meta {
  color: var(--author-meta-color);
  font-size: 0.875rem;
  margin-top: 2px;
}

.author__page-link {
  color: inherit;
  text-decoration: none;
}

.author__role {
  color: #555;
}

.author__arrow {
  grid-area: arrow;
  align-self: center;
  font-size: 1.5rem;
  margin-right: var(--spacing-m);
}
