.photoswipe-link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  grid-area: 1 / 1;
  text-decoration: none;
}

.photoswipe-link__text {
  position: absolute !important;
  display: block;
  background-color: var(--color-background);
  color: var(--color-link);
  font-weight: 500;
  padding: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}


.photoswipe-link:not(:focus-visible) .photoswipe-link__text {
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px);
  white-space: nowrap;
}
