.slideshow {
  --_slides-image-background-color: var(--theme-slides-image-background-color, #d6dfe0);
  --_slides-btn-size-mobile: var(--theme-slides-btn-size-mobile, 1.75rem);
  --_slides-btn-size-desktop: var(--theme-slides-btn-size-desktop, 3rem);
  --_slides-btn-icon-size-mobile: var(--theme-slides-btn-icon-size-mobile, 1.25rem);
  --_slides-btn-icon-size-desktop: var(--theme-slides-btn-icon-size-desktop, 1.5rem);
  --_slides-btn-icon-stroke: var(--theme-slides-btn-icon-stroke, currentColor);
  --_slides-btn-icon-stroke-width: var(--theme-slides-btn-icon-stroke-width, 2);
  --_slides-btn-background-color: var(--theme-slides-btn-background-color, var(--color-background));
  --_slides-btn-border-width: var(--theme-slides-btn-border-width, 1px);
  --_slides-btn-border-width-focus: var(--theme-slides-btn-border-width-focus, 3px);
  --_slides-btn-border-color: var(--theme-slides-btn-border-color, var(--color-border-separator));
  --_slides-btn-border-color-focus: var(--theme-slides-btn-border-color-focus, var(--color-text));
  --_slides-btn-border-radius: var(--theme-slides-btn-border-radius, 50%);

  display: block; /* custom elements are inline by default */
  position: relative;

  --slideshow-button-bg: rgba(0, 0, 0, 0.2);
  --slideshow-button-bg-hover: rgba(0, 0, 0, 1);

  &:hover {
    --slideshow-button-bg: rgba(0, 0, 0, 0.6);
  }
}

.slideshow, .slideshow--orientation-landscape {
  --slide-aspect-ratio: 4/3;
}

.slideshow--orientation-mixed {
  --slide-aspect-ratio: 1/1;
}

.slideshow--orientation-portrait {
  --slide-aspect-ratio: 3/4;
}

.slideshow--active {
  .slideshow__items {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }

  .slideshow__items::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.slideshow__items {
  margin: 0;
  display: flex;
  overflow-x: scroll;
  overscroll-behavior-x: contain; /* disables swipe to go back/forward on iOS when overscrolling */
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  width: 100%;
  scroll-behavior: smooth;
  list-style: none;
  padding: 0;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

.slideshow__item {
  flex: 1 0 100%;
  scroll-snap-align: start;
  height: 100%;
  width: 100%;
}

.slideshow__figure {
  height: 100%;
  margin: 0;
  aspect-ratio: var(--slide-aspect-ratio);
}

.slideshow__item img {
  flex: 1 1 0%;
  min-height: 0; /* this is the key to not having the image be taller than the container */
  margin: auto;
  object-fit: contain;
  background-color: var(--_slides-image-background-color);
}

.slideshow__controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.slideshow__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: all;
  background-color: var(--color-background);
  border: var(--_slides-btn-border-width) solid var(--_slides-btn-border-color);
  border-radius: var(--_slides-btn-border-radius);
  color: var(--color-text);
  cursor: pointer;
  width: var(--_slides-btn-size);
  height: var(--_slides-btn-size);
  padding: var(--_slides-btn-padding);
  transition:
    background-color 0.15s,
    color 0.15s,
    border-color 0.15s;

  --_slides-btn-padding: calc(
    (
      var(--_slides-btn-size) - var(--_slides-btn-icon-size) - var(--_slides-btn-border-width) * 2
    )
    / 2
  );

  --_slides-btn-size: var(--_slides-btn-size-mobile);
  --_slides-btn-icon-size: var(--_slides-btn-icon-size-mobile);

  @media (min-width: 63.25em) {
    --_slides-btn-size: var(--_slides-btn-size-desktop);
    --_slides-btn-icon-size: var(--_slides-btn-icon-size-desktop);
  }

  svg {
    transform: translateX(0);
    transition: transform 0.15s;
    width: var(--_slides-btn-icon-size);
    height: var(--_slides-btn-icon-size);
    display: block;

    path {
      stroke: var(--_slides-btn-icon-stroke);
      stroke-width: var(--_slides-btn-icon-stroke-width);
    }
  }

  &[aria-disabled="true"] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.slideshow__button-prev {
  left: calc(var(--_slides-btn-size) / 2 * -1);
}

.slideshow__button-next {
  right: calc(var(--_slides-btn-size) / 2 * -1);
}

.slideshow__button:hover:not([aria-disabled="true"]),
.slideshow__button:focus-visible:not([aria-disabled="true"]) {
  border-color: var(--_slides-btn-border-color-focus);
  cursor: pointer;
}

.slideshow__button:focus-visible {
  --_slides-btn-border-width: var(--_slides-btn-border-width-focus);
  --_slides-btn-border-color: var(--_slides-btn-border-color-focus);
  outline: 2px solid var(--color-background);
}
