:root {
  --teaser-image-margin: 1.88rem;
  --teaser-marker-font-size: 0.875rem;
  --teaser-marker-line-height: 1.1428;
  --teaser-marker-padding-bottom: 0.625rem;
  --teaser-gutter-inline: var(--theme-teaser-gutter-inline);
  --teaser-gutter-block: var(--theme-teaser-gutter-block);
}

.teaser {
  --teaser-stacked-grid:
    [gutter-top-start section-start] 0
    [gutter-top-end] var(--teaser-marker-height, auto)
    [section-end image-start] auto
    [image-end] 1rem
    [title-start] auto
    [title-end] 0.75rem
    [drophead-start] minmax(0, 1fr)
    [drophead-end gutter-bottom-start] var(--teaser-gutter-block, 0)
    [gutter-bottom-end]
    /
    [gutter-left-start fullwidth-start] var(--teaser-gutter-inline, 0)
    [gutter-left-end content-start] 1fr
    [content-end gutter-right-start] var(--teaser-gutter-inline, 0)
    [gutter-right-end fullwidth-end];
  --teaser-inline-grid:
    [gutter-top-start image-start section-start] auto
    [gutter-top-end] auto
    [section-end title-start] auto
    [title-end] 0.5rem
    [drophead-start] minmax(0, 1fr)
    [drophead-end gutter-bottom-start] var(--teaser-gutter-block, 0)
    [gutter-bottom-end]
    /
    [gutter-left-start fullwidth-start] var(--teaser-gutter-inline, 0)
    [gutter-left-end image-start] var(--teaser-image-width, auto)
    [image-end] var(--teaser-image-margin, 0)
    [content-start] 1fr
    [content-end gutter-right-start] var(--teaser-gutter-inline, 0)
    [gutter-right-end fullwidth-end];
  --teaser-inline-reverse-grid:
    [gutter-top-start image-start section-start] auto
    [gutter-top-end] auto
    [section-end title-start] auto
    [title-end] 0.5rem
    [drophead-start] minmax(0, 1fr)
    [drophead-end gutter-bottom-start] var(--teaser-gutter-block, 0)
    [gutter-bottom-end]
    /
    [gutter-left-start fullwidth-start] var(--teaser-gutter-inline, 0)
    [gutter-left-end content-start] 1fr
    [content-end] var(--teaser-image-margin, 0)
    [image-start] var(--teaser-image-width, auto)
    [image-end gutter-right-start] var(--teaser-gutter-inline, 0)
    [gutter-right-end fullwidth-end];
  display: grid;
  grid-template: var(--teaser-grid-template, var(--teaser-inline-grid, initial));
}

@supports (display: grid) {
  .teaser__title,
  .teaser__drophead {
    margin: 0;
  }
}

.teaser--large {
  --teaser-drophead-font-size: 1.125rem;
  --teaser-image-grid-column: content;
  --teaser-grid-template: var(--teaser-stacked-grid);
  --teaser-title-font-size: 26px;

  @media (min-width: 40em) {
    &.teaser--in-collection {
      --teaser-drophead-font-size: 1rem;
    }
  }

  @media (min-width: 63.25em) {
    --teaser-title-font-size: 1.375rem;
    &:not(.teaser--in-collection) {
      --teaser-image-width: 40%;
      --teaser-marker-padding-bottom: 0.375rem;
      --teaser-title-font-size: 2.375rem;
      --teaser-image-grid-column: image;
      --teaser-grid-template: var(--teaser-inline-reverse-grid);
    }
  }
}

.teaser--small {
  --teaser-image-width: 5rem;
  --teaser-image-margin: 0.625rem;
  --teaser-title-font-size: 1.125rem;
  --teaser-image-grid-column: image;

  @media (min-width: 40em) {
    &.teaser--in-collection {
      --teaser-drophead-font-size: 1rem;
      --teaser-image-grid-column: content;
      --teaser-grid-template: var(--teaser-stacked-grid);
      --teaser-title-font-size: 1.375rem;
    }
  }

  @media (min-width: 63.25em) {
    &:not(.teaser--in-collection) {
      --teaser-image-width: 11.25rem;
      --teaser-image-margin: 1.25rem;
      --teaser-marker-padding-bottom: 0.375rem;
      --teaser-title-font-size: 1.25rem;
      --teaser-drophead-font-size: 1rem;
    }
  }
}

.teaser__marker {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  padding-bottom: var(--teaser-marker-padding-bottom);
  font-size: var(--teaser-marker-font-size);
  line-height: var(--teaser-marker);
  grid-column: content;
  grid-row: section;

  &.placeholder {
    display: none;
    padding-bottom: none;
  }

  @media (min-width: 40em) {
    &.placeholder {
      display: block;
    }
  }
}

.teaser__section {
  color: var(--color-brand);
}

.teaser__native-marker {
  color: var(--color-text);
  text-transform: upppercase;
}

.teaser__title-prefix {
  color: var(--color-brand);
}

.teaser__image {
  --teaser-image-border-radius: 6px;
  display: block;
  width: 100%;
  height: auto;
  grid-column: var(--teaser-image-grid-column, content);
  grid-row: var(--teaser-image-grid-row, image);

  &:where(picture) {
    border-radius: 0;
    img {
      border-radius: var(--teaser-image-border-radius);
      display: block;
      width: 100%;
      height: auto;
    }
  }
  border-radius: var(--teaser-image-border-radius);
}

.teaser__title {
  grid-column: content;
  grid-row: title;
  font-size: var(--teaser-title-font-size, 1.375rem);
  line-height: 1.15;
  font-weight: 900;
  text-wrap: auto;
  margin: 0;
}

.teaser__title-prefix {
  color: var(--color-brand);
}

.teaser__title-link {
  color: inherit;
  text-decoration: var(--teaser-heading-decoration, var(--default-link-decoration, none));

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.teaser__drophead {
  grid-column: content;
  grid-row: drophead;
  max-width: 40.625rem; /* 650px */
  font-size: var(--teaser-drophead-font-size, 1rem);
  text-wrap: pretty;
}

.teaser-list {
  --teaser-list-separator-color: var(--color-border-separator);
  --teaser-list-inside-margin: 0.5rem;

  /* Any direct child following another gets border top and margin, or any teaser-list directly following another */
  & > *:not(.ad-container) + *:not(.teaser-collection:has(.teaser-collection__vignette)):not(.ad-container):not(.spinner),
  &:not(:has(:last-child.ad-container)) + .teaser-list,
  & > gen-poof-widget > .teaser-list {
    padding-top: var(--spacing-l);
    margin-top: var(--teaser-list-inside-margin);
    border-top: 1px solid var(--teaser-list-separator-color);
  }
  @media (min-width: 40em) {
    --teaser-list-inside-margin: var(--spacing-l);
  }
}

.teaser-collection {
  --teaser-collection-separator-color: var(--color-border-separator);
  position: relative;

  &:not(:first-child) {
    margin-top: 2rem;
  }

  &::before {
    content: "";
    border-left: 4px solid var(--color-brand);
    width: 0;
    height: 100%;
    margin-left: -1rem;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .teaser {
    --teaser-gutter-block: 0;

    &:not(:first-child) {
      margin-top: var(--spacing-s)
    }
  }

  @media (min-width: 40em) {
    border-top: 2px solid var(--color-brand);

    & .teaser {
      &:not(:first-child) {
        margin-top: 0;
      }
    }

    &:not(:first-child) {
      margin-top: 4rem;
    }

    &::before {
      content: none;
    }
  }
}

.teaser-collection__teasers {
  display: grid;
  grid-row-gap: 1.25rem;
  grid-column-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
  @media (min-width: 40em) {
    grid-row-gap: 2.5rem;
    --teaser-marker-height: calc(
      var(--teaser-marker-font-size) * var(--teaser-marker-line-height) + var(--teaser-marker-padding-bottom)
    );
  }
}

.teaser-collection__vignette {
  --teaser-collection-vignette-margin: 0 0 0.625rem 0;
  margin: var(--teaser-collection-vignette-margin);
  line-height: 1.5626rem;

  a, span {
    text-decoration: none;
    font-weight: 900;
    color: var(--color-brand);
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;

    @media (min-width: 40em) {
      font-size: 1.25rem;
      --teaser-collection-vignette-margin: 0 0 0.75rem 0;
    }
  }
}

.teaser-collection__vignette-icon {
  width: 1.5626rem;
  height: 1.5625rem;
  margin-left: 0.125rem;
  color: var(--color-brand);
}
