.tags {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 0.25rem;
  padding: 0;
  align-items: center;

  > span {
    font-size: 0.75rem;
    font-weight: bold;
  }

  .tag a {
    padding: 4px 12px;
    font-size: 0.75rem;
    border: 1px solid var(--theme-color-brand);
    border-radius: 12px;
    text-decoration: none;
    color: var(--theme-color-brand);
    
    &:hover {
      background-color: var(--theme-color-brand);
      color: var(--theme-color-text-on-brand);
    }
  }
}
