:root {
  --bad-fullpage-header-height: var(--theme-bad-fullpage-header-height, 9.375rem); /* Needed for BAD to calculate some rich media ad format heights. 150px default, does not need to be exact. */
}

.rich-media {
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .ad-info_button {
    display: none;
  }

  &[hidden] {
    display: none;
  }
}

.rich-media--unveiled {
  --rma-opacity: 1;
}

.rich-media__ui {
  flex: none;
  opacity: var(--rma-opacity, 0);
  transition: 0.15s;
}

.rich-media__button {
  border: none;
  padding: 1rem;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.125rem;
}

.rich-media__ad-marker {
  color: var(--color-text-secondary);
}

.rich-media__button-label {
  color: var(--color-text);
  display: flex;
  align-items: center;
}

.rich-media__ad {
  flex: 1 1 100%;
  background-color: rgba(0,0,0,0.02);
  opacity: var(--rma-opacity, 0);

  b-a-d[slotname^="rich_media"] {
    display: none !important;
  }

  @media (min-width: 768px) {
    b-a-d[slotname^="rich_media"] {
      display: flex !important;
    }
  }

  @media (min-width: 768px) {
    b-a-d[slotname^="mob"] {
      display: none !important;
    }
  }
}
