.section-header {
  margin-bottom: var(--spacing-l);

  h1 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .section-header__description {
    --prose-font-size: 1.25rem;

    @media (min-width: 63.25em) {
      width: 90%;
    }
  }
}
