.outsider-content {
  --outsider-content-maxwidth: 61.25rem; /* 980px */
  --outsider-adspace-width: 18.75rem; /* 300px */
  --outsider-grid-gap: 2.5rem;
  display: flex;
}
@media (min-width: 84.5em) {
  .outsider-content {
    display: grid;
    grid-template-columns: var(--outsider-content-maxwidth) var(--outsider-adspace-width);
    grid-gap: var(--outsider-grid-gap);
  }
}

.outsider-content__main {
  max-width: var(--outsider-content-maxwidth); /* 980px */
  width: 100%;
}

.outsider-content__outsider {
  display: none;

  @media (min-width: 84.5em) {
    display: block;
  }

  .ad-container[data-slotname="outsider"]:last-child {
    position: sticky;
    top: 0;
  }
}
