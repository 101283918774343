@import url("../themes/lifestyle.css");

@font-face {
  font-family: 'Source Sans';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../../fonts/sourcesans/SourceSans3VF-Upright.woff2') format('woff2-variations');
}
/**
 * Fallback font for when the primary font fails to load.
 *
 * Normally uses a system font where adjustments are made
 * to match the primary font as closely as possible.
 * This prevents layout shifts before the primary font has loaded.
 *
 * Use Malte Ubl's calculator to generate the values:
 * @see https://deploy-preview-15--upbeat-shirley-608546.netlify.app/perfect-ish-font-fallback/
 */
 @font-face {
  font-family: ArialFallback;
  size-adjust: 93.75%;
  ascent-override: 110%;
  src: local(Arial);
}

@font-face {
  font-family: 'Source Sans';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('../../fonts/sourcesans/SourceSans3VF-Italic.woff2') format('woff2-variations');
}

@font-face {
  font-family: ArialFallback;
  size-adjust: 93.75%;
  ascent-override: 110%;
  src: local(Arial);
}

:root {
  --theme-color-brand: #cb2229;
  --theme-color-text-on-brand: white;
  --theme-color-text: #222;
  --theme-color-background: #fff;
  --theme-teaser-gutter-block: 0.75rem;
  --theme-teaser-gutter-inline: 0;
  --theme-font-base-family: 'Source Sans';
  --theme-font-fallback-family: ArialFallback;
  --theme-font-system-fallback: sans-serif;
}
