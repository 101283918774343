gen-embed-resource {
  &[active] {
    --_aspect-ratio: 16/9;

    &[data-resource-supplier="facebookpost"] {
      --_aspect-ratio: 4/3;
    }

    .embed-resource__placeholder {
      display: none;
    }
  }

  &[blocked] {
    .embed-resource__preftoggle {
      display: block;
    }

    iframe {
      display: none;
    }

    .expanding-iframe-container {
      background-color: #f2f2f2;
    }
  }
}

.expanding-iframe-container {
  aspect-ratio: var(--_aspect-ratio, auto);
}

.embed-resource__placeholder {
  padding: 10px;
}

.embed-resource__placeholder button {
  margin-right: 10px;
}