.factbox {
  padding: var(--spacing-l);
  background-color: var(--color-factbox-background);
  border-radius: 6px;

  & h2 {
    margin-bottom: var(--spacing-l);
  }

  & ol, ul {
    margin: 0;
    padding-left: var(--spacing-l);
  }

  & li + li {
    margin-top: var(--spacing-s);
  }
}
