:root {
  --spacing-s: var(--theme-spacing-s, 8px);
  --spacing-m: var(--theme-spacing-m, 16px);
  --spacing-l: var(--theme-spacing-l, 20px);
  --spacing-xl: var(--theme-spacing-xl, 50px);
  --color-background: var(--theme-color-background, #f4f4f4);
  --color-border-separator: #d2d2d2;
  --color-brand: var(--theme-color-brand, #3a3a3a);
  --color-factbox-background: var(--theme-factbox-background, #d6dfe0);
  --color-text: var(--theme-color-text, #000000);
  --color-text-secondary: var(--theme-color-text-secondary, #767676);
  --font-family-body: var(--theme-font-family-body, sans-serif);
  --color-footer-background: var(--theme-footer-background, #222222);
  --font-base-family: var(--theme-font-base-family, Arial);
  --font-fallback-family: var(--theme-font-fallback-family, sans-serif);
  --font-system-fallback: sans-serif;
}
